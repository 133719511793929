import React, { useState, useEffect } from "react";
import Donut from "../../common/donutChart/donutChart";
import ReactStars from "react-rating-stars-component";
import ProgressBar from "@ramonak/react-progress-bar";
import MixedChart from "../../common/mountainBarChart/mountainBarChart";
import { FaRegThumbsUp, FaRegThumbsDown } from "react-icons/fa";
import ReviewTable from "../../common/customTable"; // Correct import
import HighChart from "../../common/high-chart/highChart";

const Reviews = ({
  companyName2,
  data2,
  reviews,
  ratings,
  insights,
  sentiments,
  positive,
  neg,
  ptheme,
  ntheme,
  positivebar,
  positives,
  negatives
}) => {
  console.log("🚀 ~ positivebar:", positivebar)
  const [loading, setLoading] = useState(true);
  const [hasData, setHasData] = useState(false);

  useEffect(() => {
    // Simulate loading delay
    const timer = setTimeout(() => {
      setLoading(false);
      setHasData(!!positivebar && positivebar.length > 0);
    }, 1000); // Adjust timing as needed

    return () => clearTimeout(timer);
  }, [positivebar]);

  function transformSentimentTrend(sentimentTrend) {
    const result = {
      positiveCount: [],
      neutralCount: [],
      negativeCount: [],
      totalRating: [],
      averageRating: [],
      positivePercentage: [],
      neutralPercentage: [],
      negativePercentage: [],
      year: [],
      month: [],
    };

    if (Array.isArray(sentimentTrend)) {
      sentimentTrend.forEach((item) => {
        for (const key in result) {
          result[key].push(item[key]);
        }
      });
    } else {
      console?.warn("Expected an array but received:", sentimentTrend);
      return result;
    }

    return result;
  }

  const transformedData = transformSentimentTrend(positivebar);

  const {
    positiveCount,
    neutralCount,
    negativeCount,
    totalRating,
    averageRating,
    positivePercentage,
    neutralPercentage,
    negativePercentage,
    year,
    month,
 

  } = transformedData;

  const hasSentimentData = Array.isArray(positivebar) && positivebar.length > 0;

  const SummaryWithNewLines = ({ summary }) => {
    if (!summary) return null;

    const formattedSummary = summary?.split("\n").map((item, index) => (
      <span key={index}>
        {item}
        <br />
      </span>
    ));

    return <div>{formattedSummary}</div>;
  };

  function extractThemes(text, type) {
    const regex = new RegExp(`${type} Reviews:[\\s\\S]*?(?=###|$)`, "i");
    const sectionMatch = text?.match(regex);

    if (!sectionMatch) return [];

    const themeRegex = /\d+\.\s(.+?)\s-\s(\d+)%/g;
    const themes = [];
    let match;

    while ((match = themeRegex.exec(sectionMatch[0])) !== null) {
      const [_, theme, percentage] = match;
      themes.push({ theme, percentage: parseInt(percentage, 10) });
    }

    return themes;
  }

  const positiveThemes = extractThemes(sentiments, "Positive");
  const negativeThemes = extractThemes(sentiments, "Negative");
  const positiveLabels = positiveThemes.map((item) => item.theme);
  const positivePercentages = positiveThemes.map((item) => item.percentage);
  const negativeLabels = negativeThemes.map((item) => item.theme);
  const negativePercentages = negativeThemes.map((item) => item.percentage);

  function getPercentages(totalReport) {
    return [
      totalReport[0]?.postive.percentage,
      totalReport[0]?.negative.percentage,
      totalReport[0]?.neutral.percentage,
    ];
  }
  const data = getPercentages(reviews);
  const data2Array = data2 && Object?.values(data2);

  const color1 = ["#18C5E2", "#DD1515", "#8DCCD7"];
  const color2 = ["#18C5E2", "#DD1515", "#8DCCD7", "#FFCA91"];
  const ratingChanged = (newRating) => {
    console?.log(newRating);
  };

  const positiveReviewData = positives?.jsonResponse?.positive || {};
  const negativeReviewData = negatives?.jsonResponse?.negative || {};
  
  console.log('Raw Positive Data:', positiveReviewData);
  console.log('Raw Negative Data:', negativeReviewData);
  
  const positiveData = {
    percentages: positive || [],
    themes: ptheme || []
  };
  
  const negativeData = {
    percentages: neg || [],
    themes: ntheme || []
  };
  
  console.log('Processed Positive Data:', positiveData);
  console.log('Processed Negative Data:', negativeData);

  return (
    <>   
    <div className="container mx-auto my-4 py-4 bg-[#ffff] rounded-lg">
      <div className="p-4">
        <h2 className="text-[18px] border-b-2 pb-2 border-[#696969] text-[#2A2A2A]">
          Reviews from Solar Review{" "}
        </h2>
      </div>
      <div className="mychart grid grid-cols-[1fr_2fr] shadow-lg max-lg:grid-cols-1 gap-8 p-4">
        {loading ? (
          <div className="flex justify-center items-center h-full">
            <p className="text-[20px] font-semibold text-blue-600">
              Loading...
            </p>
          </div>
        ) : (
          <>
            {hasData ? (
               
              <Donut
                graphNumber={data}
                themes={["positive", "negative", "neutral"]}
                color2={color1}
              />
              // <HighChart
              // graphNumber={data}
              //  themes={["positive", "negative", "neutral"]}
              //  color2={color1}/>
              
            ) : (
              <div className="flex justify-center items-center h-full">
                <p className="text-[20px] font-semibold text-red-600">
                  There are no reviews for this company
                </p>
              </div>
            )}
          </>
        )}

        {data2Array?.length > 0 ? (
          <div className="overview border rounded-lg bg-white">
            <p className="bg-[#016171] text-white text-center py-2 rounded-t-lg">
              Overview
            </p>

            <div className="px-4">
              <h2 className="text-[#2A2A2A]">Customer Reviews</h2>

              <div className="py-4">
                <div className="flex justify-between items-center py-2">
                  <p className="text-[#696969] text-[14px]">
                    Neutral Reviews Rating
                  </p>
                  <ReactStars
                    count={5}
                    onChange={ratingChanged}
                    size={24}
                    activeColor="#ffd700"
                  />
                </div>
                <ProgressBar
                  completed={data2Array[2]}
                  height="5px"
                  bgColor="#8DCCD7"
                  labelColor="#8DCCD7"
                  isLabelVisible={false}
                />
                <div className="flex justify-between items-center py-2">
                  <p className="text-[#696969] text-[14px]">
                    Positive Reviews Rating
                  </p>
                  <ReactStars
                    count={5}
                    onChange={ratingChanged}
                    size={24}
                    activeColor="#ffd700"
                  />
                </div>
                <ProgressBar
                  completed={data2Array[0]}
                  height="5px"
                  bgColor="#18C5E2"
                  labelColor="#18C5E2"
                  isLabelVisible={false}
                />
                <div className="flex justify-between items-center py-2">
                  <p className="text-[#696969] text-[14px]">
                    Negative Reviews Rating
                  </p>
                  <ReactStars
                    count={5}
                    onChange={ratingChanged}
                    size={24}
                    activeColor="#ffd700"
                  />
                </div>
                <ProgressBar
                  completed={data2Array[1]}
                  height="5px"
                  bgColor="#DD1515"
                  labelColor="#DD1515"
                  isLabelVisible={false}
                />
              </div>
            </div>
          </div>
        ) : null}

        <div className="overview border rounded-lg bg-white">
          <p className="bg-[#016171] text-white text-center py-2 rounded-t-lg">
            Reviews Overtime
          </p>
          {loading ? (
            <div className="flex justify-center items-center h-full">
              <p className="text-[20px] font-semibold text-blue-600">
                Loading...
              </p>
            </div>
          ) : hasSentimentData ? (
          
            <MixedChart
              positivePercentage={positivePercentage}
              averageRating={averageRating}
              neutralPercentage={neutralPercentage}
              negativePercentage={negativePercentage}
              year={year}
              month={month}
            />
           
          ) : (
            <div className="flex justify-center h-full items-center">
              <p className="text-[20px] font-semibold text-red-600">
                No sentiment trend available
              </p>
            </div>
          )}
        </div>
      </div>





    </div>
    <div className="container mx-auto my-4 py-4 bg-[#ffff] rounded-lg">

    <div className="mytable web-table grid grid-cols-[1fr_1fr] bg-[#ffff] max-lg:grid-cols-1 gap-8 px-4 mt-8">
    {positive && positive.length > 0 ? (
      <ReviewTable title="Positive Reviews" data={positiveData} />
    ) : (
      <div className="flex justify-center bg-white my-8 px-8 py-8 h-full items-center">
        <p className="text-[20px] font-semibold text-red-600">
          No positive reviews are available for this company
        </p>
      </div>
    )}
  
    {neg && neg.length > 0 ? (
      <ReviewTable title="Negative Reviews" data={negativeData} />
    ) : (
      <div className="flex justify-center bg-white my-8 px-8 py-8 h-full items-center">
        <p className="text-[20px] font-semibold text-red-600">
          No negative reviews are available for this company
        </p>
      </div>
    )}
  </div>
  </div>
  </>
    
  );
};

export default Reviews;


   {/* <div className="overview border rounded-lg py-4 text-center bg-white">
          <p className="bg-white text-[#016171] text-[20px] font-bold gap-2 text-center py-3 flex justify-start items-center px-4 rounded-t-lg">
            <FaRegThumbsUp />
            Positive Reviews Breakdown
          </p>
          {positive && positive?.length > 0 ? (
            <Donut graphNumber={positive} themes={ptheme} pth color2={color2} />
          ) : (
            <div className="flex justify-center items-center h-full">
              <p className="text-[20px] font-semibold text-red-600">
                There are no positive reviews for this company
              </p>
            </div>
          )}
        </div>

        <div className="overview border rounded-lg bg-white">
          <p className="bg-white text-[#016171] text-[20px] font-bold gap-2 text-center py-3 flex justify-start items-center px-4 rounded-t-lg">
            <FaRegThumbsDown />
            Negative Reviews Breakdown
          </p>
          {neg && neg?.length > 0 ? (
            <Donut graphNumber={neg} themes={ntheme} color2={color2} />
          ) : (
            <div className="flex justify-center items-center h-full">
              <p className="text-[20px] font-semibold text-red-600">
                There are no negative reviews for this company
              </p>
            </div>
          )}
        </div> */}