import React, { useState } from "react";
import logo from "../../../assets/images/logo.png";
import email from "../../../assets/icons/email.svg";
import password from "../../../assets/icons/password.svg";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import { loginSchema } from "../../validationSchema/validationSchema";
import { useDispatch } from "react-redux";
import { LogInUserHandler } from "../../../reduxStore/loginSlice/loginSlice";
const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);
  // const dispatch=
  const initialValues = {
    email: "",
    password: "",
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      console.log("🚀 ~ onSubmit: ~ values:", values);
      await dispatch(LogInUserHandler(values)).then((response) => {
        console.log("🚀 ~ awaitdispatch ~ response:", response);
        if (response?.success) {
          Swal.fire({
            icon: "success",
            title: `${response?.message}`,
            showConfirmButton: false,
            timer: 1500,
          });
          navigate("/overview");
        } else {
          Swal.fire({
            icon: "error",
            title: `${response?.response?.data?.message}`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      });
    },
  });

  return (
    <div className="px-48 xl:px-24 lg:px-8 md:px-20 sm:px-20 max-sm:px-4 bg-gradient-to-r from-[#3550e1] to-[#141e51]  ">
      <div className="h-screen items-center gap-12 max-md:gap-2  grid 2xl:grid-cols-[1fr_1.35fr] xl:grid-cols-[1fr_1.35fr] lg:grid-cols-[1fr_1.15fr] md:grid-cols-1 sm:grid-cols-1 grid-cols-1 ">
        <div>
          <img className="w-32 h-16 max-lg:mx-auto" src={logo} alt="icon" />
          <p className="text-white text-[34px]  max-xl:text-[22px] max-md:[22px]  max-lg:text-center">
            Log In with your details to <br className=" md:hidden" /> get
            started
          </p>
        </div>
        <div className=" signup-card  max-md:mx-auto 2xl:w-[70%] xl:w-[90%] max-md:w-full">
          <div className="  bg-white rounded-lg px-12 py-12  mx-auto">
            <p className="font-medium text-[18px]">Welcome to sign in!</p>
            <p className="text-[#696969]">
              Create an account to start your application
            </p>
            <form onSubmit={formik.handleSubmit}>
              <div>
                <div className="mt-[38px]">
                  <p
                    className=" text-[#141e51]  text-sm font-medium  mb-2 flex items-center"
                    htmlFor="email"
                  >
                    <img src={email} alt="email" className="mr-2" /> Email
                    address
                  </p>
                  <input
                    type="email"
                    id="email"
                    placeholder="Example@gmail.com"
                    name="email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    className=" appearance-none border rounded text-[14px] w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <p className="text-xs mt-1 text-red-500">
                      {formik.errors.email}
                    </p>
                  ) : null}
                </div>

                <div className="mt-[28px]">
                  <p
                    className=" text-[#141e51]  text-sm font-medium  mb-2 flex items-center"
                    htmlFor="password"
                  >
                    <img src={password} alt="email" className="mr-2" /> Password
                  </p>
                  <input
                    type="password"
                    id="password"
                    placeholder="password"
                    name="password"
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    className=" appearance-none border rounded text-[14px] w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                  {formik.touched.password && formik.errors.password ? (
                    <p className="text-xs mt-1 text-red-500">
                      {formik.errors.password}
                    </p>
                  ) : null}
                </div>
                <div className="mt-[28px] flex gap-2 justify-between items-center">
                  <div className="flex gap-2 items-center">
                    <input
                      type="checkbox"
                      id="isChecked"
                      name="isChecked"
                      checked={isChecked}
                      onChange={(e) => setIsChecked(e.target.checked)}
                      className=" border text-[14px] rounded-sm py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                    <p className="text-[#696969]">Remember Me</p>
                  </div>
                  <div>
                    <Link to={"/forget-password"}>
                      <p className="text-[#141e51] font-medium underline">
                        {" "}
                        Forgot Password?
                      </p>
                    </Link>
                  </div>
                </div>

                <div className="mt-[28px]">
                  <button
                    type="submit"
                    className="group transition-all bg-gradient-to-r from-[#141e51] to-[#3550e1] w-full text-white font-medium py-2 px-4 rounded focus:outline-none focus:shadow-outline "
                  >
                    Log in
                  </button>
                </div>
              </div>
              <div className="flex justify-between mt-2 items-center" >
                <div>
<p className="text-[16px] font-normal text-[#696969]">Dont have an account?</p>
                </div>
<div>
<Link to='/signup'>  
<p className="text-[16px] font-normal underline text-[#141e51] ">Sign up?</p>
</Link>
</div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Login;
