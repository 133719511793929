import React, { useRef } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const HighChart = ({ themes, graphNumber, color1, color2, data }) => {
  const chartComponentRef = useRef(null);

  const options = {
    chart: {
      type: 'pie',
      innerSize: '50%', // This makes it a donut chart
    },
    title: {
      text: 'Egg Yolk Composition',
    },
    tooltip: {
      valueSuffix: '%',
    },
    subtitle: {
      text: 'Source: <a href="https://www.mdpi.com/2072-6643/11/3/684/htm" target="_default">MDPI</a>',
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          distance: 20,
          style: {
            fontSize: '1.2rem',
            textOutline: 'none',
            opacity: 0.9,
          },
          filter: {
            operator: '>',
            property: 'percentage',
            value: 10,
          },
        },
      },
    },
    series: [
      {
        name: "",
        colorByPoint: color2,
        data: themes.map((theme, index) => ({
          name: theme,
          y: graphNumber[index], // Ensure `graphNumber` matches `themes` length
          color: color1 ? color1[index] : undefined, // Optionally use colors from color1 array
        })),
      },
    ],
  };

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        ref={chartComponentRef}
      />
    </div>
  );
};

export default HighChart;
