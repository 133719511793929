import React from "react";
import Navbar from "../../common/navbar/navbar";
import { Link } from "react-router-dom";
import passwordResetIcon from '../../assets/images/SuccessResetPassword.png';
import backArrow from "../../assets/icons/left-arrow.svg";
const SuccessResetPassword=()=>{
return(
    <div className="container mx-auto h-screen  flex flex-col max-sm:px-4 max-sm:pt-4">
        <Navbar/>
        <div className="flex justify-center items-center  h-screen  text-center">
        <div className=" ">
            <div className="flex justify-center">
            <img className="text-center" src={passwordResetIcon} alt="resetpassword" />
            </div>
            <p className="font-medium text-[54px] max-md:text-[30px] text-[#016171] ">Successful Password Reset!</p>
            <p className="text-[#696969] font-normal text-[18px] max-md:text-[14px] pb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor <br className="max-lg:hidden"/> incididunt ut labore et dolore magna aliqua.</p>
            <div className="my-[28px]">
              <Link to={"/login"}>
                {" "}
                <button
                  type="submit"
                  className="group transition-all bg-[#2A2A2A] w-full text-white font-medium py-2 px-4 rounded focus:outline-none focus:shadow-outline "
                >
                Log In
                </button>
              </Link>
            </div>
            <Link to={'/login'}>
            <p className="flex justify-center gap-2 text-[18px] font-medium ">
                <img src={backArrow} alt="leftarrow" />
                Back To Log In
                </p>
                </Link>
            </div>
        </div>
    </div>
)
}
export default SuccessResetPassword;