import React from "react";

const CommentSection = () => {
  const data = [
    {
      category: "Full Name",
      ronaldo:
        "Cristiano Ronaldo dos Santos Aveiro Cristiano Ronaldo dos Santos Aveiro Cristiano RoCristiano Ronaldo dos Santos Aveiro Cristiano Ronaldo dos Santos Aveiro Cristiano RoCristiano Ronaldo dos Santos Aveiro Cristiano Ronaldo dos Santos Aveiro Cristiano RoCristiano Ronaldo dos Santos Aveiro Cristiano Ronaldo dos Santos Aveiro Cristiano RoCristiano Ronaldo dos Santos Aveiro Cristiano Ronaldo dos Santos Aveiro Cristiano Ro Cristiano Ronaldo dos Santos Aveiro Cristiano Ronaldo dos Santos Aveiro Cristiano RoCristiano Ronaldo dos Santos Aveiro Cristiano Ronaldo dos Santos Aveiro Cristiano Ronaldo dos Santos Aveiro Cristiano Ronaldo dos Santos AveiroCristiano Ronaldo dos Santos Aveiro",
      messi: "Lionel Andrés Messi Cuccittini",
    },
    {
      category: "Date of Birth",
      ronaldo: "February 5, 1985",
      messi: "June 24, 1987",
    },
    { category: "Nationality", ronaldo: "Portuguese", messi: "Argentine" },
    {
      category: "Height",
      ronaldo: "1.87 m (6 ft 2 in)",
      messi: "1.70 m (5 ft 7 in)",
    },
    {
      category: "Playing Position",
      ronaldo: "Forward",
      messi: "Forward / Attacking Midfielder",
    },
    { category: "Career Goals", ronaldo: "800+", messi: "750+" },
    { category: "Ballon d'Or Awards", ronaldo: "5", messi: "7" },
    { category: "UEFA Champions League Titles", ronaldo: "5", messi: "4" },
    { category: "League Titles", ronaldo: "7", messi: "11" },
    {
      category: "International Trophies",
      ronaldo: "2 (Euro 2016, Nations League 2019)",
      messi: "2 (Copa America 2021, World Cup 2022)",
    },
  ];

  const reviews = [
    {
      id: 1,
      heading: "Great service!",
      review:
        "I had an excellent experience with this company. The installation was quick and the staff was very professional.",
      name: "John Doe",
      date: "2024-07-20",
    },
    {
      id: 2,
      heading: "Disappointing experience",
      review:
        "There were significant delays in the installation process, and the customer service was not helpful when I tried to address these issues.",
      name: "Jane Smith",
      date: "2024-07-18",
    },
    {
      id: 3,
      heading: "Mixed feelings",
      review:
        "While the final product is good, the sales process felt a bit misleading. They should be more upfront about potential additional costs.",
      name: "Alex Johnson",
      date: "2024-07-15",
    },
    {
      id: 4,
      heading: "Quality concerns",
      review:
        "The initial installation seemed fine, but we've had some issues with the product quality over time. Hope they can improve on this.",
      name: "Sam Brown",
      date: "2024-07-10",
    },
  ];
  const item1 = {
    name: "Product A",
    features: [
      "I had an excellent experience with this company. The installation was quick and the staff was very professi",
      "Feature 2 of Product A",
      "Feature 3 of Product A",
    ],
  };

  const item2 = {
    name: "Product B",
    features: [
      "I had an excellent experience with t had an excellent experience with t had an excellent experience with t had an excellent experience with t had an excellent experience with t had an excellent experience with t had an excellent experience with t had an excellent experience with t had an excellent experience with this company. The installation was quick and the staff was very professi",
      "Feature 2 of Product A",
      "Feature 3 of Product A",
    ],
  };
  return (
    <div className="container mx-auto">
      {/* <h2 className="text-[#016171] font-medium text-[28px] my-4">Reviews</h2> */}
      <div className="bg-[#F4F7FA] p-8 rounded-md shadow-md">
        <div className="grid grid-cols-2 gap-4">
          {reviews.map((data) => (
            <div key={data.id} className="border rounded-md p-4 bg-white mb-4">
              <p>Review #{data.id}</p>
              <p className="text-[20px] font-semibold capitalize truncate">
                {data.heading}
              </p>
              <div className="min-h-36 overflow-hidden text-ellipsis line-clamp-6">
                <span className="text-[#696969]">{data.review}</span>
              </div>
              <p className="pt-4">
                Client name:{" "}
                <span className="text-[#696969] font-normal">{data.name}</span>
              </p>
              <p>
                Date:{" "}
                <span className="text-[#696969] font-normal">{data.date}</span>
              </p>
            </div>
          ))}
        </div>
      </div>
      <div className="bg-[#F4F7FA] p-8 rounded-md mt-6 shadow-md">
        <div className="flex mt-4 bg-white mb-4 min-h-[400px] shadow-md px-4 border border-[#696969]  rounded-md border-opacity-40">
          {/* Left column */}
          <div className="w-1/2 pr-4">
            <h2 className="text-2xl font-bold mt-4 mb-4">{item1.name}</h2>
            <ul className="list-disc pl-5">
              {item1.features.map((feature, index) => (
                <li key={index} className="mb-2">
                  {feature}
                </li>
              ))}
            </ul>
          </div>

          {/* Divider */}
          <div className="border-r border-gray-300"></div>

          {/* Right column */}
          <div className="w-1/2 bg-white pl-4">
            <h2 className="text-2xl mt-4 font-bold mb-4">{item2.name}</h2>
            <ul className="list-disc pl-5">
              {item2.features.map((feature, index) => (
                <li key={index} className="mb-2">
                  {feature}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommentSection;
