import React, { useState } from "react";
import logo from "../../../assets/images/logo.png";
import email from "../../../assets/icons/email.svg";
import user from "../../../assets/icons/user.svg";
import password from "../../../assets/icons/password.svg";
import { useFormik } from "formik";
import { signUpSchema } from "../../validationSchema/validationSchema";
import axios from "../authenticateApi/authenticateApi";
import { USER_SIGNUP } from "../../../utils/baseUrl";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
const Signup = () => {
  const navigate = useNavigate();
  const initialValues = {
    email: "",
    firstName: "",
    lastName: "",
    password: "",
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: signUpSchema, // Ensure the schema is connected
    validateOnBlur: true, // These are enabled by default
    validateOnChange: true,
    onSubmit: async (values) => {
      const payload = {
        ...values,
      };
      try {
        const response = await axios.post(USER_SIGNUP, payload);
        if (response?.status === 201) {
          Swal.fire({
            icon: "success",
            title: `${response?.data?.message}`,
            showConfirmButton: false,
            timer: 2500,
          });
          navigate("/login");
        } else {
          Swal.fire({
            icon: "error",
            title: `${response?.data?.error}`,
            showConfirmButton: false,
            timer: 2500,
          });
        }
      } catch (error) {
        if (error?.response?.status === 403) {
          Swal.fire({
            icon: "error",
            title: `${error?.response?.data?.detail}`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
  });

  return (
    <div className="px-48 xl:px-24 lg:px-8 md:px-20 sm:px-20 max-sm:px-4  bg-gradient-to-r from-[#3550e1] to-[#141e51] ">
      <div className="   h-screen items-center gap-12 md:gap-2  grid 2xl:grid-cols-[1fr_1.35fr] xl:grid-cols-[1fr_1.35fr] lg:grid-cols-[1fr_1.15fr] md:grid-cols-1 sm:grid-cols-1 grid-cols-1 ">
        <div className="">
          <img className="w-32 h-16 max-lg:mx-auto" src={logo} alt="icon" />
          <p className="text-white text-[34px] max-xl:text-[22px] max-md:[22px]  max-lg:text-center">
            Get customer insights <br className=" md:hidden" /> to inform credit
            decisions.
          </p>
        </div>
        <div className=" signup-card max-md:mx-auto 2xl:w-[70%] xl:w-[90%] max-md:w-[90%]">
          <div className="  bg-white rounded-lg px-12 py-8  ">
            <p className="font-medium text-[18px]">Welcome to sign up!</p>
            <p className="text-[#696969]">
              Create an account to start your application
            </p>
            <form onSubmit={formik.handleSubmit}>
              <div>
                <div className="mt-[38px]">
                  <p
                    className=" text-[#141e51]  text-sm font-medium  mb-2 flex items-center"
                    htmlFor="email"
                  >
                    <img src={email} alt="email" className="mr-2" /> Email
                    address
                  </p>
                  <input
                    type="email"
                    id="email"
                    placeholder="Example@gmail.com"
                    name="email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    className=" appearance-none border rounded text-[14px] w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <p className="text-xs mt-1 text-red-500">
                      {formik.errors.email}
                    </p>
                  ) : null}
                </div>
                <div className="mt-[28px] max-md:mt-0 flex gap-2   max-md:flex-col ">
                  <div className="firstName w-full  max-md:my-[28px]">
                    <p
                      className=" text-[#141e51]  text-sm font-medium  mb-2 flex items-center"
                      htmlFor="firstName"
                    >
                      <img src={user} alt="first" className="mr-2" /> First name
                    </p>
                    <input
                      type="text"
                      id="firstName"
                      placeholder="First name..."
                      name="firstName"
                      onChange={formik.handleChange}
                      value={formik.values.firstName}
                      className=" appearance-none border rounded text-[14px] w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                    {formik.touched.firstName && formik.errors.firstName ? (
                      <p className="text-xs mt-1 text-red-500">
                        {formik.errors.firstName}
                      </p>
                    ) : null}
                  </div>
                  <div className="lastName w-full ">
                    <p
                      className=" text-[#141e51]  text-sm font-medium  mb-2 flex items-center"
                      htmlFor="lastName "
                    >
                      <img src={user} alt="last" className="mr-2" /> Last name
                    </p>
                    <input
                      type="text"
                      id="lastName"
                      placeholder="Last name..."
                      name="lastName"
                      onChange={formik.handleChange}
                      value={formik.values.lastName}
                      className=" appearance-none border rounded text-[14px] w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                    {formik.touched.lastName && formik.errors.lastName ? (
                      <p className="text-xs mt-1 text-red-500">
                        {formik.errors.lastName}
                      </p>
                    ) : null}
                  </div>
                </div>
                {/* <div className="mt-[28px]">
                  <p
                    className=" text-[#016171]  text-sm font-medium  mb-2 flex items-center"
                    htmlFor="lastName"
                  >
                    <img src={password} alt="email" className="mr-2" /> Password
                  </p>
                  <input
                    type="text"
                    id="lastName"
                    placeholder="lastName"
                    name="lastName"
                    onChange={formik.handleChange}
                    value={formik.values.lastName}
                    className=" appearance-none border rounded text-[14px] w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                  {formik.touched.lastName && formik.errors.lastName ? (
                    <p className="text-xs mt-1 text-red-500">
                      {formik.errors.lastName}
                    </p>
                  ) : null}
                </div> */}
                <div className="mt-[28px]">
                  <p
                    className=" text-[#141e51]  text-sm font-medium  mb-2 flex items-center"
                    htmlFor="password"
                  >
                    <img src={password} alt="email" className="mr-2" /> Password
                  </p>
                  <input
                    type="password"
                    id="password"
                    placeholder="password"
                    name="password"
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    className=" appearance-none border rounded text-[14px] w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                  {formik.touched.password && formik.errors.password ? (
                    <p className="text-xs mt-1 text-red-500">
                      {formik.errors.password}
                    </p>
                  ) : null}
                </div>
                <div className="mt-[28px]">
                  {" "}
                  <button
                    type="submit"
                    className="group transition-all bg-gradient-to-r from-[#141e51] to-[#3550e1] w-full text-white font-medium py-2 px-4 rounded focus:outline-none focus:shadow-outline "
                  >
                    Start Application
                  </button>
                </div>
           
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Signup;
