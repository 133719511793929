import React from "react";
import welcomeLogo from "../../assets/images/cuiq.png";
import { Link } from "react-router-dom";
const ForgetPassword = () => {
  return (
    <div>
      <div className="login-page-container grid 2xl:grid-cols-[1fr_1.35fr] xl:grid-cols-[1fr_1.35fr] lg:grid-cols-[1fr_1.15fr] md:grid-cols-[1fr_1fr] sm:grid-cols-1   grid-cols-1 h-screen overflow-hidden  max-md:overflow-y-auto ">
        <div className="left max-md:order-last   flex justify-center items-center px-16  max-md:px-8">
          <div className="">
            <img className="w-32 h-16 mb-20 max-md:mb-8" src={welcomeLogo} alt="icon" />
            <p className="text-[#141e51] text-[18px]  font-medium mb-4 max-md:mb-0">
              Forgot Password?
            </p>
            <p className="text-[#696969] text-[18px] ">
              Enter the email address or mobile number associated with your
              account.
            </p>
            <div className="firstName my-8">
              <p
                className=" text-[#141e51]   font-medium  mb-2 "
                //   htmlFor="email"
              >
                Enter Email Or Mobile Number
              </p>
              <input
                type="text"
                id="email"
                placeholder="Enter Email Or Mobile Number"
                name="email"
                //   onChange={formik.handleChange}
                //   value={formik.values.email}
                className=" appearance-none border rounded text-[14px] w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
              {/* {formik.touched.email && formik.errors.email ? (
                  <p className="text-xs mt-1 text-red-500">
                    {formik.errors.email}
                  </p>
                ) : null} */}
            </div>
            <div className="mt-[28px]">
              <Link to={"/otp-verification"}>
                {" "}
                <button
                  type="submit"
                  className="group transition-all bg-gradient-to-r from-[#3550e1] to-[#141e51] w-full text-white font-medium py-2 px-4 rounded focus:outline-none focus:shadow-outline "
                >
                 Continue
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="right bg-gradient-to-r from-[#3550e1] to-[#141e51] text-white flex justify-center items-center text-5xl max-lg:text-4xl max-md:h-[180px]">
          <p>Forgot password!</p>
        </div>
      </div>
    </div>
  );
};
export default ForgetPassword;
