import React, { useState, useEffect } from 'react';
import welcomeLogo from "../../assets/images/cuiq.png";

const getBase64Image = (img) => {
  const canvas = document.createElement("canvas");
  canvas.width = img.width;
  canvas.height = img.height;
  const ctx = canvas.getContext("2d");
  ctx.drawImage(img, 0, 0);
  return canvas.toDataURL("image/png");
};

const convertImageToBase64 = (imagePath, callback) => {
  const img = new Image();
  img.crossOrigin = 'Anonymous'; // Important for cross-origin images
  img.src = imagePath;
  img.onload = () => {
    const base64String = getBase64Image(img);
    callback(base64String);
  };
};

const PdfContainer = ({ createPdf, children, bodyRef, isPdfPreview }) => {
  const [logoBase64, setLogoBase64] = useState('');

  useEffect(() => {
    convertImageToBase64(welcomeLogo, setLogoBase64);
  }, []);
  console.log("image")
  return (
    <section className="pdf-container">
      <section className="pdf-body" ref={bodyRef}>
        {isPdfPreview && (
          <div className="pdf-header">
          <img src={welcomeLogo} alt="Company Logo" className="company-logo w-32 h-auto" />
          </div>
        )}
        {children}
        {isPdfPreview && (
          <div className="pdf-footer">
            <p className="generated-by text-[#243697] text-[28px] font-medium">Generated by cuiq.com</p>
          </div>
        )}
      </section>
    </section>
  );
};

export default PdfContainer;
