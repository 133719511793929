import React from "react";
import logo from "../../assets/images/logo.png";
import rightArrow from "../../assets/icons/right-arrow.png";
import { Link } from "react-router-dom";

const HomePage = () => {
  return (
    <div className="bg-gradient-to-r from-[#3550e1] to-[#141e51] h-screen flex flex-col justify-between items-center py-4">
      <div></div>
      <div>
        <img className="max-sm:w-[80%] max-sm:mx-auto" src={logo} alt="icon" />
      </div>
      <Link to={"/welcome"}>
        <div className="flex gap-2  justify-end items-end">
          <p className="text-white">Get Started</p>
          <img className="w-7 h-5 " src={rightArrow} alt="arrow" />
        </div>
      </Link>
    </div>
  );
};
export default HomePage;
