import React, { useState } from 'react';
import Chart from 'react-apexcharts';
const Donut = ({ themes, graphNumber, color1, color2 }) => {
    const [options] = useState({
        chart: {
            type: 'donut'
        },
        colors: color2 || [], // Provide a default empty array if color2 is undefined
        dataLabels: {
            enabled: true, // Enable data labels
            formatter: (val, opts) => {
                const label = opts.w.globals.labels[opts.seriesIndex] || '';
                return `${label} ${val.toFixed(0)}%`;
            },
            style: {
                fontSize: '14px',
                colors: ['#016171']
            },
            dropShadow: {
                enabled: false
            }
        },
        plotOptions: {
            pie: {
                dataLabels: {
                    offset: 0, // Adjust the offset to control the position of labels
                    minAngleToShowLabel: 10,
                    connector: {
                        enabled: true,
                        length: '20%',
                        length2: '30%',
                        style: {
                            strokeWidth: 1,
                            strokeColor: '#000' // Connector line color
                        }
                    }
                }
            }
        },
        legend: {
            show: false
        }
    });
    // Ensure graphNumber is an array, if not, convert it to one
    const series = Array.isArray(graphNumber) ? graphNumber : [graphNumber];
    // Ensure themes is an array, if not, use an empty array
    const labels = Array.isArray(themes) ? themes : [];
    return (
        <div className="donut w-120 max-sm:w-80">
            <Chart options={{ ...options, labels }} series={series} type="donut" />
        </div>
    );
};
export default Donut;