import { savePDF } from '@progress/kendo-react-pdf';

const createPdf = (html) => {
  savePDF(html, {
    paperSize: 'A4',
    scale: 0.6,
    fileName: 'Cuiq.pdf',
    margin: 20,
    creator: 'cuiq.com',
    author: 'cuiq.com',
    title: 'Cuiq Report',
  });
};

const DocService = {
  createPdf,
};

export default DocService;