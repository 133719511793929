import React, { useState, useRef, useEffect } from "react";
import NavbarAfterLogin from "../../common/navbarAfterLogin/navbarAfterLogin";
import SearchBar from "../../components/SearchBar/searchBar";
import axios from "../../components/Auth/authenticateApi/authenticateApi";
import { InfinitySpin } from "react-loader-spinner";

const removeHTMLTags = (str) => {
  return str?.replace(/<\/?[^>]+(>|$)/g, "");
};

const DemoTesting = ({ companyName }) => {
  const [selected, setSelected] = useState("");
  const [data, setData] = useState("");
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [error, setError] = useState(null);
  const loaderRef = useRef(null);
  const token = localStorage.getItem("token");

  const options = ["positive", "negative", "neutral", "sentiment"];

  const scrollToLoader = () => {
    if (loaderRef.current) {
      loaderRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  useEffect(() => {
    if (loading) {
      scrollToLoader();
    }
  }, [loading]);

  const handleItemClick = async (companyName) => {
    setSearchTerm(companyName);
    setIsFocused(false);
    setLoading(true);

    const url = selected
      ? `http://198.71.57.69:7070/reviews/testPropmt/${encodeURIComponent(
          searchTerm
        )}?promptType=${encodeURIComponent(selected)}`
      : `http://198.71.57.69:7070/reviews/testPropmt/${encodeURIComponent(
          searchTerm
        )}`;

    try {
      const res = await axios.post(
        url,
        { prompt: data },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setResponse(res.data);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const SummaryWithNewLines = ({ summary }) => {
    if (!summary) return null;

    return (
      <div>
        {summary?.split("\n")?.map((item, index) => (
          <span key={index}>
            {item}
            <br />
          </span>
        ))}
      </div>
    );
  };

  const handleChange = (e) => {
    setData(e.target.value);
  };

  const handleClear = () => {
    setResponse(null);
    setSelected("");
    setData("");
    setSearchTerm("");
  };

  return (
    <div>
      <NavbarAfterLogin />
      <div className="searchBar overview-background flex flex-col justify-center items-center">
        <p className="capitalize text-[54px] max-lg:text-[40px] max-md:text-[30px] text-white text-center font-bold">
          The search engine for{" "}
          <span className="text-gradient-to-r from-[#141e51] to-[#3550e1]">
            customer reviews
          </span>
        </p>
        <p className="text-[22px] text-[#EEFDFF] text-center">
          Get comprehensive insights from customer reviews to inform credit
          decisions.
        </p>
        <SearchBar
          handleItemClick={handleItemClick}
          value2={false}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          isFocused={isFocused}
          setIsFocused={setIsFocused}
        />
        <div>
          <select
            value={selected}
            onChange={(e) => setSelected(e?.target?.value)}
            className="w-full px-4 py-2 mt-10 rounded-md bg-white border border-gray-300 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="" disabled>
              Select an option
            </option>
            {options.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          <div>
            <div className="w-[1000px] mx-auto mt-10">
              <textarea
                className="w-full p-3 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500 focus:ring-2 focus:ring-blue-200 resize-none transition duration-200 ease-in-out"
                rows="10"
                placeholder="Enter your text here..."
                onChange={handleChange}
                value={data}
              ></textarea>
            </div>
          </div>
        </div>
        <button
          className="bg-gradient-to-r mt-4 from-[#141e51] to-[#3550e1] px-12 py-3 text-white rounded-full"
          onClick={() => handleItemClick(searchTerm)}
        >
          Submit
        </button>
        <button
          className="bg-gradient-to-r mt-4 from-[#141e51] to-[#3550e1] px-12 py-3 text-white rounded-full"
          onClick={handleClear}
        >
          Clear
        </button>
      </div>
      {loading ? (
        <div
          ref={loaderRef}
          className="flex items-center justify-center h-screen"
        >
          <InfinitySpin
            height="200"
            width="200"
            radius="9"
            color="#1a202c"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      ) : (
        <div>
          {!response ? (
            <p className="text-gray-500">Loading...</p>
          ) : selected === "positive" &&
            response?.response?.jsonResponse?.positive_reviews ? (
            <div className="border border-green-300 rounded-lg p-4 max-w-md mx-auto bg-green-50">
              <h3 className="text-lg font-semibold text-green-800 mb-2">
                Positive Reviews
              </h3>
             <div className="text-green-600 my-2">
                <span className="font-bold">Percentages:</span>
                <ul className="list-disc list-inside">
                  {response.response.jsonResponse.positive_reviews.percentages?.map(
                    (percentage, index) => (
                      <li key={index}>{percentage}</li>
                    ) 
                  )}
                </ul>
              </div>
              <div className="text-green-600 my-2">
                <span className="font-bold">Themes:</span>
                <ul className="list-disc list-inside">
                  {response?.response?.jsonResponse?.positive_reviews.themes?.map(
                    (theme, index) => (
                      <li key={index}>{theme}</li>
                    )
                  )}
                </ul>
              </div>
              {response.response.jsonResponse.summary ? (
                <div>
                  <h3 className="text-lg text-green-600 font-semibold">
                    Summary
                  </h3>
                  <p className="text-green-600">
                    {" "}
                    {response.response.jsonResponse.summary}{" "}
                  </p>
                </div>
              ) : null}
            </div>
          ) : selected === "negative" &&
            response?.response?.jsonResponse?.negative_reviews ? (
            <div className="border border-red-300 rounded-lg p-4 max-w-md mx-auto bg-red-50">
              <h3 className="text-lg font-semibold text-red-800 mb-2">
                Negative Reviews
              </h3>
              {/* <div className="text-red-600 my-2">
                <span className="font-bold">Percentages:</span>
                <ul className="list-disc list-inside">
                  <li>
                    {response.response.jsonResponse.negative_reviews.percentage}
                    %
                  </li>
                </ul>
              </div> */}
              <div className="text-red-600 my-2">
                <span className="font-bold">Themes:</span>
                <ul className="list-disc list-inside">
                  {Array.isArray(
                    response.response.jsonResponse.negative_reviews.themes
                  ) ? (
                    response.response.jsonResponse.negative_reviews.themes?.map(
                      (theme, index) => <li key={index}>{theme}</li>
                    )
                  ) : (
                    <p>
                      {response.response.jsonResponse.negative_reviews.themes}
                    </p>
                  )}
                </ul>
              </div>
                 <div className="text-red-600 my-2">
                <span className="font-bold">Percentages:</span>
                <ul className="list-disc list-inside">
                  {response?.response?.jsonResponse.negative_reviews.percentages?.map(
                    (percentage, index) => (
                      <li key={index}>{percentage}</li>
                    ) 
                  )}
                </ul>
              </div>
              <div>
                {response.response.jsonResponse.summary ? (
                  <div>
                    <h3 className="text-lg text-red-600 font-semibold">
                      Summary
                    </h3>
                    <p className="text-red-600">
                      {response.response.jsonResponse.summary}
                    </p>
                  </div>
                ) : null}
              </div>
            </div>
          ) : selected === "sentiment" &&
            response?.response?.jsonResponse?.average_sentiment_scores ? (
            <div className="border border-gray-300 rounded-lg p-4 max-w-md mx-auto bg-gray-50">
              <h3 className="text-lg font-semibold text-gray-800 mb-2">
                Sentiment Analysis
              </h3>
              <div className="text-gray-600 my-2">
                <span className="font-bold">Average Sentiment Scores:</span>
                {typeof response.response.jsonResponse
                  .average_sentiment_scores === "object" &&
                !Array.isArray(
                  response.response.jsonResponse.average_sentiment_scores
                ) ? (
                  <ul className="list-disc list-inside">
                    {Object.entries(
                      response.response.jsonResponse.average_sentiment_scores
                    )?.map(([key, value]) => (
                      <li key={key}>
                        <span className="font-bold">{key}:</span> {value}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <div>
                    <p className="block">
                      {response.response.jsonResponse.average_sentiment_scores.join(
                        ", "
                      )}
                    </p>
                  </div>
                )}
              </div>
              <div className="text-gray-600 my-2">
                <span className="font-bold">Months:</span>
                {typeof response.response.jsonResponse.months === "object" &&
                !Array.isArray(response.response.jsonResponse.months) ? (
                  <ul className="list-disc list-inside">
                    {Object.entries(response.response.jsonResponse.months)?.map(
                      ([key, value]) => (
                        <li key={key}>
                          <span>{key}:</span> {value}
                        </li>
                      )
                    )}
                  </ul>
                ) : (
                  <div>
                    {response.response.jsonResponse.months?.map(
                      (month, index) => (
                        <p key={index} className="block">
                          {month}
                        </p>
                      )
                    )}
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="border border-gray-300 rounded-lg p-4 container mt-6 mx-auto bg-gray-50">
              <p>{response?.response?.jsonResponse}</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default DemoTesting;
