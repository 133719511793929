import React from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "./Pages/homePage/homePage";
import WelcomePage from "./Pages/welcomePage/welcomePage";
import Signup from "./components/Auth/signup/signup";
import Login from "./components/Auth/login/login";
import ForgetPassword from "./Pages/forgetPassword/forgetPassword";
import OTPVerification from "./Pages/forgetPassword/otpVerification";
import ResetPassword from "./Pages/forgetPassword/resetPassword";
import SuccessResetPassword from "./Pages/forgetPassword/passwordResetSuccess";
import Overview from "./Pages/overViewPage/overViewPage";
import Reviews from "./Pages/reviewsbySite/reviewsbySite";
import DemoTesting from "./Pages/DemoTesting";
import CommentSection from "./Pages/CommentSection";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/welcome" element={<WelcomePage />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/login" element={<Login />} />
      <Route path="/forget-password" element={<ForgetPassword />} />
      <Route path="/otp-verification" element={<OTPVerification />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route
        path="/password-reset-successfully"
        element={<SuccessResetPassword />}
      />
      <Route path="/overview" element={<Overview />} />
      <Route path="/demo-testing" element={<DemoTesting />} />
      <Route path="/comment-section" element={<CommentSection />} />

      <Route path="/review" element={<Reviews />} />
    </Routes>
  );
};
export default AppRoutes;
