import React, { useState, useEffect } from "react";
import axios from "../Auth/authenticateApi/authenticateApi";

const SearchBar = ({
  handleItemClick,
  value2,
  searchTerm,
  setSearchTerm,
  isFocused,
  setIsFocused,
}) => {
  const [companies, setCompanies] = useState([]);
  const [value, setValue] = useState("");
  const [filteredCompanies, setFilteredCompanies] = useState([]);

  useEffect(() => {
    // Fetch companies when the component mounts
    axios
      .get("https://api.getcuiq.com/reviews/getCompnays")
      .then((response) => {
        setCompanies(response?.data?.companyNames);
        setFilteredCompanies(response?.data?.companyNames);
      })
      .catch((error) => {
        console.error("There was an error fetching the companies!", error);
      });
  }, []);

  const handleInputChange = (event) => {
    const { value } = event.target;
    setSearchTerm(value);

    // Filter the companies based on the search term
    const filtered = companies?.filter((company) =>
      company?.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredCompanies(filtered);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setIsFocused(false);
    }
  };

  const handlefunction = (company) => {
    setValue(company);
    setSearchTerm(company);
    setIsFocused(false);
  };

  const handleFunction2 = (value) => {
    handleItemClick(value);
  };

  return (
    <div
      className="relative w-3/6 mt-8 max-md:w-[80%]"
      onBlur={handleBlur}
      tabIndex="0"
    >
      <label
        htmlFor="default-search"
        className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
      >
        Search
      </label>
      <div className="flex justify-center items-center w-full">
        <div className="relative flex-grow">
          <div className="absolute inset-y-0 start-0 flex items-center p-5 pointer-events-none">
            <svg
              className="w-6 h-6 text-[#696969]"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
              />
            </svg>
          </div>
          <input
            type="search"
            id="default-search"
            value={searchTerm}
            onChange={handleInputChange}
            onFocus={handleFocus}
            className="block w-full outline-none rounded-full p-4 ps-16 text-sm text-gray-900 border border-gray-300 bg-gray-50 focus:ring-white focus:border-white"
            placeholder="Search Company Name Or URL..."
            required
          />
        </div>

        <div className="flex justify-center ml-4">
          {value2 ? (
            <button
              className="bg-gradient-to-r from-[#141e51] to-[#3550e1] px-12 py-3 text-white rounded-full"
              onClick={() => handleFunction2(value)}
            >
              Search
            </button>
          ) : null}
        </div>
      </div>

      {isFocused && filteredCompanies?.length > 0 && (
        <ul className="absolute z-10 w-[49rem] bg-white border border-gray-300 rounded-md mt-2 max-h-60 overflow-y-auto">
          {filteredCompanies?.map((company, index) => (
            <li
              key={index}
              className="px-2 py-1 text-[#666666] hover:bg-gray-200 cursor-pointer border-b"
              onClick={() => handlefunction(company)}
              tabIndex="0"
            >
              {company}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SearchBar;
