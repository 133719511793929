import React from 'react';

const ReviewTable = ({ title, data }) => {
    console.log('ReviewTable Data:', data); 
  const { themes = [], percentages = [] } = data || {};

  const combinedData = themes.map((theme, index) => ({
    theme,
    percentage: percentages[index] || 0,
  }));


  return (
    <div className="mb-8">
      <h2 className="font-bold text-lg mb-2">{title}</h2>
      <table className="w-full text-left border border-gray-200">
        <thead>
          <tr className="bg-gray-100">
            <th className="py-2 px-4">Theme</th>
            <th className="py-2 px-4 text-center">Percentage</th>
          </tr>
        </thead>
        <tbody>
          {combinedData.map((item, index) => (
            <tr key={index} className="border-t border-gray-200">
              <td className="py-2 px-4">{item.theme}</td>
              <td className="py-2 px-4 text-center">{item.percentage}%</td>
            </tr>
          ))}
     
        </tbody>
      </table>
    </div>
  );
};

export default ReviewTable;
