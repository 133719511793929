import React from 'react';

const PercentageLoader = ({ progress }) => {
  // Round the progress to the nearest whole number
  const roundedProgress = Math.round(progress);

  return (
    <div className="loader-container">
      <div className="loader-text">Loading...</div>
      <div className="progress-bar">
        <div
          className="progress-bar-fill"
          style={{ width: `${roundedProgress}%` }} // Reflect progress
        ></div>
      </div>
      <div className="progress-percentage">{roundedProgress}%</div>
    </div>
  );
};

export default PercentageLoader;
