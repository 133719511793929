import React, { useState } from "react";
import welcomeLogo from "../../assets/images/cuiq.png";
import { Link } from "react-router-dom";
import backArrow from "../../assets/icons/left-arrow.svg";
import password from '../../assets/icons/password.svg';
import eyeOn from '../../assets/icons/eyeOn.png';
import eyeOff from '../../assets/icons/eyeOff.png'
const ResetPassword = () => {
    
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };
  return (
    <div>
      <div className="login-page-container grid 2xl:grid-cols-[1fr_1.35fr] xl:grid-cols-[1fr_1.35fr] lg:grid-cols-[1fr_1.15fr] md:grid-cols-[1fr_1fr] sm:grid-cols-1   grid-cols-1 h-screen overflow-hidden max-md:overflow-y-auto ">
        <div className="left p-24 max-md:order-last max-md:p-8 max-lg:px-8  ">
          <div className="">
            <img className="w-32 h-16 mb-20 max-lg:mb-8" src={welcomeLogo} alt="icon" />
            <Link to={'/otp-verification'}>
            <p className="flex gap-2 text-[18px] font-medium mb-4">
              <img
                className="w-7 h-6 font-bold"
                src={backArrow}
                alt="backarrow"
              />
              Back To OTP
            </p>
            </Link>
            <p className="text-[#141e51] text-[18px] font-medium ">
            Reset Your Password
            </p>
            <p className="text-[#696969] font-normal text-[18px] ">
            Please Enter Your New Password
            </p>
            <div className="firstName my-8 relative">
              <p
                className=" text-[#141e51] flex gap-2 items-center  font-medium  mb-2 "
                //   htmlFor="email"
              >
                <img src={password} alt="password" />
                New Password
              </p>
              <input
                type= {passwordShown ? "text" : "password"}
                id="email"
                placeholder="Password..."
                name="email"
                //   onChange={formik.handleChange}
                //   value={formik.values.email}
                className=" appearance-none border rounded text-[14px] w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
                <button
                  onClick={togglePasswordVisibility}
                  className="absolute h-8 w-8 inset-y-0 right-0 top-9 pr-3 flex items-center text-sm leading-5"
                  type="button"
                >
                  {passwordShown ? (
                    <img src={eyeOn} alt="eye" />
                  ) : (
                    <img src={eyeOff} alt="eye" />
                  )}
                </button>
              {/* {formik.touched.email && formik.errors.email ? (
                  <p className="text-xs mt-1 text-red-500">
                    {formik.errors.email}
                  </p>
                ) : null} */}
            </div>
            <div className="firstName my-8 relative">
              <p
                className=" text-[#141e51] flex gap-2 items-center  font-medium  mb-2 "
                //   htmlFor="email"
              >
                <img src={password} alt="password" />
                Confirm New Password
              </p>
              <input
                type="text"
                id="email"
                placeholder="Password..."
                name="email"
                //   onChange={formik.handleChange}
                //   value={formik.values.email}
                className=" appearance-none border rounded text-[14px] w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
                <button
                  onClick={togglePasswordVisibility}
                  className="absolute h-8 w-8 inset-y-0 right-0 top-9 pr-3 flex items-center text-sm leading-5"
                  type="button"
                >
                  {passwordShown ? (
                    <img src={eyeOn} alt="eye" />
                  ) : (
                    <img src={eyeOff} alt="eye" />
                  )}
                </button>
              {/* {formik.touched.email && formik.errors.email ? (
                  <p className="text-xs mt-1 text-red-500">
                    {formik.errors.email}
                  </p>
                ) : null} */}
            </div>
            <div className="mt-[28px]">
              <Link to={"/password-reset-successfully"}>
                {" "}
                <button
                  type="submit"
                  className="group transition-all bg-gradient-to-r from-[#3550e1] to-[#141e51] w-full text-white font-medium py-2 px-4 rounded focus:outline-none focus:shadow-outline "
                >
                 Reset Password
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="right bg-gradient-to-r from-[#3550e1] to-[#141e51] text-white flex justify-center items-center text-5xl max-lg:text-4xl max-sm:text-3xl max-md:h-[180px]">
          <p>Reset Your Password</p>
        </div>
      </div>
    </div>
  );
};
export default ResetPassword;
