import React from "react";
import ReactApexChart from "react-apexcharts";

const MixedChart = ({ positivePercentage, year, month , negativePercentage ,neutralPercentage,averageRating}) => {
  const concatenatedArray = month.map((month, index) => `${month} ${year[index]}`);

  console.log("🚀 ~ MixedChart ~ positivePercentage:", positivePercentage);
  console.log("🚀 ~ MixedChart ~ concatenatedArray:", concatenatedArray);
  const options = {
    chart: {
      height: 480,
      type: "line",
      stacked: false,
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#016171", "#dd1515", "#66C7F4", "#8dccd7"],
    series: [
      {
        name: "Positive",
        type: "column",
        data: positivePercentage,
      },
      {
        name: "Negative",
        type: "column",
        data: negativePercentage,
      },
      {
        name: "Neutral",
        type: "column",
        data: neutralPercentage,
      },
      {
        name: "Average",
        type: "line",
        data: averageRating,
      },
    ],
    stroke: {
      width: [4, 4, 4, 2], // Ensure line stroke width is specified
    },
    plotOptions: {
      bar: {
        columnWidth: "20%",
      },
    },
    xaxis: {
      categories: concatenatedArray,
    },
    yaxis: [
      {
        seriesName: "Positive",
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
        },
        title: {
          text: "Columns",
        },
        labels: {
          formatter: function (value) {
            return Math.round(value); // Convert to integer
          },
        },
      },
      {
        seriesName: "Negative",
        show: false,
      },
      {
        seriesName: "Neutral",
        show: false,
      },
      {
        opposite: true,
        seriesName: "Average",
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        labels: {
          show: false, // Hide labels on the right side
        },
        title: {
          text: "Line",
        },
      },
    ],
    tooltip: {
      shared: true, // Set shared to true for better comparison
      intersect: false,
      x: {
        show: true, // Enable x-axis labels in tooltip
      },
    },
    legend: {
      horizontalAlign: "left",
      offsetX: 40,
    },
  };
  

  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={options.series}
        type="line"
        height={350}
      />
    </div>
  );
};

export default MixedChart;
