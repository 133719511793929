import React, { useState, useRef, useEffect } from "react";
import NavbarAfterLogin from "../../common/navbarAfterLogin/navbarAfterLogin";
import SearchBar from "../../components/SearchBar/searchBar";
import axios from "../../components/Auth/authenticateApi/authenticateApi";
import Reviews from "../reviewsbySite/reviewsbySite";
import { LuExternalLink } from "react-icons/lu";
import { RiChat3Line, RiCheckLine, RiChat1Line } from "react-icons/ri";
import { AiOutlineWarning } from "react-icons/ai";
import PercentageLoader from "../../components/loader";
import { FaArrowTrendDown } from "react-icons/fa6";
import DocService from "../../components/docService";
import PdfContainer from "../../components/pdfContainer";
import { savePDF } from "@progress/kendo-react-pdf";

const removeHTMLTags = (str) => str?.replace(/<\/?[^>]+(>|$)/g, "");
const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

const Overview = ({ companyName }) => {
  const [image, setImage] = useState('');
  const imageRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingPercentage, setLoadingPercentage] = useState(0);
  const [progress, setProgress] = useState(0);
  const [isFocused, setIsFocused] = useState(false);
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState(null);
  const [isPdfPreview, setIsPdfPreview] = useState(false);
  const [logo, setLogo] = useState('');
  const loaderRef = useRef(null);
  const bodyRef = useRef(null);
  const startTimeRef = useRef(null);
  const progressIntervalRef = useRef(null);

  const token = localStorage.getItem("token");

  const startProgress = (estimatedDuration) => {
    startTimeRef.current = performance.now();
    progressIntervalRef.current = setInterval(() => {
      const elapsedTime = performance.now() - startTimeRef.current;
      const elapsedPercentage = Math.min((elapsedTime / estimatedDuration) * 100, 100);
      setLoadingPercentage((prev) => Math.round(Math.max(prev, elapsedPercentage)));
    }, 500);
  };

  const stopProgress = () => {
    if (progressIntervalRef.current) {
      clearInterval(progressIntervalRef.current);
      progressIntervalRef.current = null;
    }
  };

  const scrollToLoader = () => {
    if (loaderRef.current) {
      loaderRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  useEffect(() => {
    if (loading) {
      scrollToLoader();
    }
  }, [loading]);

  const handleItemClick = async (companyName) => {
    setSearchTerm(companyName);
    setIsFocused(false);
    setLoading(true);
    setProgress(0);
    setLoadingPercentage(0);

    const estimatedDuration = 10000; // 10 seconds for example
    startProgress(estimatedDuration);

    try {
      const start = performance.now();

      const response = await axios.get(
        `https://api.getcuiq.com/reviews/getReport?companyName=${encodeURIComponent(companyName)}`,
        {
          onDownloadProgress: (progressEvent) => {
            const totalLength = progressEvent.lengthComputable
              ? progressEvent.total
              : 100;
            const progressPercentage = Math.round(
              (progressEvent.loaded * 100) / totalLength
            );
            setProgress(progressPercentage);
            setLoadingPercentage(progressPercentage); // Ensure this is an integer
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const end = performance.now();
      const actualDuration = end - start;

      if (actualDuration > estimatedDuration) {
        startProgress(actualDuration);
      }

      setProgress(100);
      setLoadingPercentage(100);
      setUserData(response.data);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
      stopProgress();
      handleClear();
    }
  };

  const handleClear = () => {
    setSearchTerm("");
  };

  useEffect(() => {
    setImage(userData?.companydetail?.logo);
  }, [userData]);

  console.log("imageTest", image);

  const handleCreatePdf = () => {
    setIsPdfPreview(true);
    setTimeout(() => {
      if (bodyRef.current) {
        DocService.createPdf(bodyRef.current);
      }
      setIsPdfPreview(false);
    }, 100);
  };

  useEffect(() => {
    if (userData?.rating?.logo) {
      setLogo(userData.rating.logo);
    }
  }, [userData]);

  return (
    <div>
      <NavbarAfterLogin />
      <div className="searchBar overview-background flex flex-col justify-center items-center">
        <p className="capitalize text-[54px] max-lg:text-[40px] max-md:text-[30px] text-white text-center font-bold">
          The search engine for{" "}
          <span className="text-gradient-to-r from-[#141e51] to-[#3550e1]">
            customer reviews
          </span>
        </p>
        <p className="text-[22px] text-[#EEFDFF] text-center">
          Get comprehensive insights from customer reviews to inform credit
          decisions.
        </p>
        <div className="flex gap-2 justify-center items-end w-full"> 
        <SearchBar
          handleItemClick={handleItemClick}
          value2={true}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          isFocused={isFocused}
          setIsFocused={setIsFocused}
          className="w-full"
        />
        <button
          onClick={handleCreatePdf}
          className={`mt-4 p-2 rounded ${
            !userData ? "hidden" : "bg-gradient-to-r from-[#141e51] to-[#3550e1] px-12 py-3 text-white rounded-full"
          } text-white`}
          disabled={!userData}
        >
          Download PDF
        </button>
        </div>
      </div>
      <PdfContainer
        createPdf={DocService.createPdf}
        bodyRef={bodyRef}
        logo={logo}
        isPdfPreview={isPdfPreview}
      >
        <div>
          {loading ? (
            <div
              ref={loaderRef}

              className="flex items-center justify-center h-screen"
            >
              <PercentageLoader progress={Math.max(progress, loadingPercentage)} />
            </div>
          ) : userData?.Summary ? (
            <div>
              {/* Company Details */}
              <div className="border w-full mx-2 my-4">
                <div className="container mx-auto flex gap-8 items-center py-4">
                  <div className="border border-[#016171] shadow-md rounded-md px-2 py-2">
                    <img
                    ref={imageRef}
                      className="w-24 h-24"
                      src={userData?.companydetail?.logo}
                      alt="Company Logo"
                      style={{ float: 'left' }} />
                  </div>
                  <div>
                    <h2 className="text-[22px] text-[#000000]">
                      {userData?.companydetail?.companyName}
                    </h2>
                    <p className="text-[#696969]">Not Available</p>
                    <p className="text-[#016171] cursor-pointer flex items-center gap-1 pt-2">
                      <a
                        href={userData?.companydetail?.url || '#'}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-[#016171] cursor-pointer flex underline items-center gap-1 pt-2"
                      >
                        Visit Website: <LuExternalLink />
                      </a>
                    </p>
                  </div>
                </div>
              </div>

              {/* Overview Section */}
              <div className="container mx-auto">
                <h2 className="text-[#016171] font-bold text-3xl my-6">
                  Overview: {userData.rating?.companyName}
                </h2>
                <div className="bg-white p-6 rounded-lg shadow-lg">
                  {Object.entries(userData?.Summary?.jsonResponse?.Overview || {}).map(([sectionKey, sectionData], index) => (
                    <div key={index} className="mb-8">
                      <h3 className="text-xl font-bold flex items-center gap-2 mb-4">
                        {sectionKey === "1. Sentiment Analysis" && (
                          <FaArrowTrendDown className="text-blue-600" />
                        )}
                        {sectionKey === "2. Common Themes in Reviews" && (
                          <RiChat1Line className="text-green-600" />
                        )}
                        {sectionKey === "3. Credit Manager Recommendations" && (
                          <AiOutlineWarning className="text-yellow-500" />
                        )}
                        {sectionKey}
                      </h3>

                      {sectionKey === "1. Sentiment Analysis" && (
                        <ul className="list-disc ml-5 space-y-2">
                          {Object.entries(sectionData).map(([key, value], subIndex) => (
                            <li key={subIndex}>
                              {key}:{" "}
                              <span className={key === "Overall sentiment" || key === "Trend" ? "text-red-500 font-semibold" : ""}>
                                {value}
                              </span>
                            </li>
                          ))}
                        </ul>
                      )}

                      {sectionKey === "2. Common Themes in Reviews" && (
                        <div className="space-y-4">
                          {["Positive Themes", "Negative Themes", "Specific Issues"].map((themeType, themeIndex) => (
                            <div key={themeIndex}>
                              <h4 className={`font-semibold mb-2 ${themeType === "Positive Themes" ? "text-green-600" :
                                  themeType === "Negative Themes" ? "text-red-500" :
                                    "text-yellow-600"
                                }`}>
                                {themeType}:
                              </h4>
                              <ul className="list-disc ml-5 space-y-1">
                                {Array.isArray(sectionData[themeType])
                                  ? sectionData[themeType].map((item, itemIndex) => (
                                    <li key={itemIndex}>{item}</li>
                                  ))
                                  : <li>{sectionData[themeType]}</li>
                                }
                              </ul>
                            </div>
                          ))}
                        </div>
                      )}

                      {sectionKey === "3. Credit Manager Recommendations" && (
                        <ul className="list-disc ml-5 space-y-2">
                          {sectionData.map((item, itemIndex) => (
                            <li key={itemIndex}>{item}</li>
                          ))}
                        </ul>
                      )}


                    </div>
                  ))}
                  {userData?.Summary?.jsonResponse?.Overview?.Conclusion && (
                    <div className="mt-8">

                      <p className="text-gray-700 m-0">{userData.Summary.jsonResponse.Overview.Conclusion}</p>
                    </div>
                  )}
                </div>
              </div>

              {/* Charts */}
              <div className="container mx-auto my-8">
                <div className="mb-8">
                  {/* Ensure the Donut Chart is on its own line */}
                  <div className="chart-container">
                    {/* Your Donut Chart Component Here */}
                  </div>
                </div>

                <div className="mb-8">
                  {/* Ensure the Mixed Chart is on its own line */}
                  <div className="chart-container">
                    {/* Your Mixed Chart Component Here */}
                  </div>
                </div>
              </div>

              {/* Reviews Component */}
              <Reviews
                companyName2={searchTerm}
                positive={userData?.positive?.jsonResponse?.positive_reviews?.percentages}
                ptheme={userData?.positive?.jsonResponse?.positive_reviews?.themes}
                ntheme={userData?.negative?.jsonResponse?.negative_reviews?.themes}
                neg={userData?.negative?.jsonResponse?.negative_reviews?.percentages}
                sentiments={userData?.sentimentAanalysis}
                data2={userData?.reviewSummary}
                insights={userData?.insight}
                ratings={userData?.TotalReport}
                reviews={userData?.TotalReport}
                positivebar={userData?.sentimentTrend}
              />

              {/* Latest Reviews */}
              <div className="bg-[#F4F7FA] container mx-auto mt-4 p-8 rounded-md shadow-md">
                <h1 className="text-[#016171] flex items-center gap-2 text-[20px] mb-4 font-bold">
                  <RiChat3Line /> Latest Reviews:
                </h1>
                <div className="grid grid-cols-1 gap-4">
                  {userData.recentReviews?.map((review) => {
                    const formattedDate = formatDate(review.date);
                    return (
                      <div
                        key={review._id}
                        className="rounded-lg shadow-lg border p-4 bg-white mb-4"
                      >
                        <div className="flex justify-between mb-6 items-center">
                          <p className="text-[#00000] font-bold">
                            Rating:{" "}
                            <span className="text-[#00000] font-bold">
                              {(review?.rating && (review.rating / 20).toFixed(2)) ||
                                "No rating available"}
                            </span>
                          </p>
                          <p>
                            <span className="text-[#696969] font-semibold">
                              {formattedDate}
                            </span>
                          </p>
                        </div>
                        <div className="min-h-36 overflow-hidden text-ellipsis line-clamp-6">
                          <span className="text-[#696969]">{review?.review}</span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </PdfContainer>
    </div>
  );
};

export default Overview;

