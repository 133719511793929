import React from "react";
import Navbar from "../../common/navbar/navbar";
import { Link } from "react-router-dom";

const WelcomePage = () => {
  return (
    <div className="bg-gradient-to-r from-[#3550e1] to-[#141e51]">  
    <div className=" container mx-auto h-screen  flex flex-col">
      <div className=" flex justify-center items-center  h-screen  text-center">
        <div className="">
          <p className="font-bold text-[#ffff] text-[57px] max-sm:text-[22px]  ">
            Welcome To Cuiq!
          </p>
          <p className="text-[#ffff] text-[18px] max-sm:text-[12px] pb-4">
            We help Credit Managers make more informed credit decisions by
            providing <br className="sm:hidden max-sm:hidden" /> insights from
            customer reviews.
          </p>
          <div className="gap-4 flex justify-center">
            <Link to={"/signup"}>
              <button className=" rounded-md shadow-md py-2 px-8 text-[#0c1649] bg-[#ffff]">
                Sign Up
              </button>
            </Link>
            <Link to={"/login"}>
              {" "}
              <button className=" rounded-md py-2 px-10 shadow-md text-white bg-[#3550e1] ">
                Log In
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};
export default WelcomePage;
