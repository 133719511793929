import React from "react";
import welcomeLogo from "../../assets/images/logo.png";
const Navbar = () => {
  return (
    <div className="">
      <img className="" src={welcomeLogo} alt="logo" />
    </div>
  );
};
export default Navbar;
