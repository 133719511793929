import { configureStore, combineReducers } from "@reduxjs/toolkit";
import {
  useDispatch as useAppDispatch,
  useSelector as useAppSelector,
} from "react-redux";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import { thunk } from "redux-thunk";
import loginSlice from "../loginSlice/loginSlice";


const reducers = combineReducers({
 loginSlice:loginSlice,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["loginSlice"],
};
const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(thunk),
  // middleware: [thunk],
});

export const persistor = persistStore(store);

export const { dispatch } = store;

export const useDispatch = () => useAppDispatch();
export const useSelector = useAppSelector;

export default store;
