import React, { useRef, useState } from "react";
import welcomeLogo from "../../assets/images/cuiq.png";
import { Link } from "react-router-dom";
import backArrow from "../../assets/icons/left-arrow.svg";
import OTPImage from "../../assets/images/OTPVerification.png";
const OTPVerification = () => {
  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const inputRef3 = useRef(null);
  const inputRef4 = useRef(null);
  const [emailOtp, setEmailOtp] = useState(["", "", "", ""]);
  const handleOTPChange = (value, index, type) => {
    let newOtp = [...emailOtp];
    newOtp[index] = value;

    if (value.length === 0 && index > 0) {
      // If the current input is empty and not the first input, focus on the previous input
      if (type === "phone") {
        // Handle phone OTP
      } else {
        if (index === 3) {
          // If the backspace is pressed in the last input, go to the previous input
          inputRef3.current.focus();
        } else if (index === 2) {
          // If the backspace is pressed in the second last input, go to the previous input
          inputRef2.current.focus();
        } else if (index === 1) {
          // If the backspace is pressed in the third input, go to the previous input
          inputRef1.current.focus();
        }
      }
    } else if (value.length === 1 && index < 3) {
      // If a digit is entered and not the last input, focus on the next input
      if (type === "phone") {
        // Handle phone OTP
      } else {
        if (index === 0) inputRef2.current.focus();
        else if (index === 1) inputRef3.current.focus();
        else if (index === 2) inputRef4.current.focus();
      }
    }
    setEmailOtp(newOtp);
  };

  const handlePaste = (e, type) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData("text/plain");

    if (pastedText.length === 4) {
      // If pasted value is 4 digits, distribute each digit to individual fields
      const newOtp = pastedText.split("");
      if (type === "phone") {
        // Handle phone OTP
      } else {
        setEmailOtp(newOtp);
        inputRef4.current.focus(); // Focus on the last input field after pasting
      }
    }
  };
  return (
    <div>
      <div className="login-page-container grid 2xl:grid-cols-[1fr_1.35fr] xl:grid-cols-[1fr_1.35fr] lg:grid-cols-[1fr_1.15fr] md:grid-cols-[1fr_1fr] sm:grid-cols-1 grid-cols-1 h-screen overflow-hidden max-md:overflow-y-auto  ">
        <div className="left max-md:order-last   flex justify-center items-center px-16 md:px-8  max-md:px-8 max-md:py-4">
          <div className="">
            <img className="w-32 h-16 mb-12  max-md:mb-8" src={welcomeLogo} alt="icon" />
            <Link to={'/forget-password'}>
            <p className="flex gap-2 text-[18px] font-medium mb-4">
              <img
                className="w-7 h-6 font-bold"
                src={backArrow}
                alt="backarrow"
              />
              Back To Forgot Password
            </p>
            </Link>
            <p className="text-[#141e51] text-[18px] font-medium mb-4 max-md:mb-0">
              Two-Step Verification
            </p>
            <img className="my-4" src={OTPImage} alt="otpImage" />
            <p className="text-[#696969] text-[18px] ">
              Enter the email address or mobile number associated with your
              account.
            </p>

            <div className="emailotp my-4 ">
              {/* <h2 className="text-[16px] font-medium py-2"> OTP</h2> */}
              <p
                className=" text-[#141e51]   font-medium  mb-2 "
                //   htmlFor="email"
              >
                Enter 4 Digits Code
              </p>
              <input
                ref={inputRef1}
                type="text"
                name="otp"
                value={emailOtp[0]}
                onPaste={(e) => handlePaste(e, "otp")}
                onChange={(e) => handleOTPChange(e.target.value, 0, "email")}
                rows={4} // Set the maximum number of rows
                maxLength={1} // Set the maximum length of OTP
                className="h-11 p-4 w-24 max-md:w-12  max-lg:w-14 rounded-sm border-[#016171] border mr-5"
              />
              <input
                ref={inputRef2}
                type="text"
                name="otp"
                value={emailOtp[1]}
                onPaste={(e) => handlePaste(e, "otp")}
                onChange={(e) => handleOTPChange(e.target.value, 1, "email")}
                rows={4} // Set the maximum number of rows
                maxLength={1} // Set the maximum length of OTP
                className="h-11 p-4 w-24 max-md:w-12  max-lg:w-14 rounded-sm border-[#016171] border mr-5"
              />
              <input
                ref={inputRef3}
                type="text"
                name="otp"
                value={emailOtp[2]}
                onPaste={(e) => handlePaste(e, "otp")}
                onChange={(e) => handleOTPChange(e.target.value, 2, "email")}
                rows={4} // Set the maximum number of rows
                maxLength={1} // Set the maximum length of OTP
                className="h-11 p-4 w-24 max-md:w-12  max-lg:w-14 rounded-sm border-[#016171] border mr-5"
              />
              <input
                ref={inputRef4}
                type="text"
                name="otp"
                value={emailOtp[3]}
                onPaste={(e) => handlePaste(e, "otp")}
                onChange={(e) => handleOTPChange(e.target.value, 3, "email")}
                rows={4} // Set the maximum number of rows
                maxLength={1} // Set the maximum length of OTP
                className="h-11 p-4 w-24 max-md:w-12  max-lg:w-14 rounded-sm border-[#016171] border mr-5"
              />
            </div>
            <div className="mt-[28px]">
              <Link to={"/reset-password"}>
                {" "}
                <button
                  type="submit"
                  className="group transition-all bg-gradient-to-r from-[#3550e1] to-[#141e51] w-full text-white font-medium py-2 px-4 rounded focus:outline-none focus:shadow-outline "
                >
                  Verify
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="right bg-gradient-to-r from-[#3550e1] to-[#141e51] text-white flex justify-center items-center text-5xl max-sm:text-4xl max-md:h-[180px]">
          <p>OTP Verification</p>
        </div>
      </div>
    </div>
  );
};
export default OTPVerification;
